import { isEmail, isPhone } from "../shared";

/**
 * Billing validation
 * @param details biling details
 * @returns list of errors or false if there is'nt
 */
export function validateBillingDetails(details: Record<string, string>) {
  let errors: boolean | Record<string, string> = false;

  if (details?.first_name?.length < 2) {
    errors = {
      first_name: "يجب أن يحتوي الاسم الأول على حرفين على الأقل"
    }
  }

  if (details?.last_name?.length < 2) {
    errors = {
      ...(errors || {}),
      last_name: "يجب أن يحتوي اسم العائلة على حرفين على الأقل"
    };
  }

  if (details?.email?.length > 0 && isEmail(details?.email) === false) {
    errors = {
      ...(errors || {}),
      email: "عنوان البريد الإلكتروني الرجاء إدخال عنوان بريد إلكتروني صالح مثل name@example.com"
    };
  }

  if (isPhone(details?.phone) === false) {
    errors = {
      ...(errors || {}),
      phone: "أدخل رقم هاتف صالحًا يتكون من رقم (0-9)"
    };
  }

  if (details?.address_1?.length < 5) {
    errors = {
      ...(errors || {}),
      address_1: "يجب أن يحتوي العنوان على 5 أحرف على الأقل"
    };
  }

  if (details?.state?.length < 2 || !details?.state) {
    errors = {
      ...(errors || {}),
      state: "يجب أن تحتوي الولاية على حرفين على الأقل"
    };
  }

  return errors === false ? errors : { billing: errors };
}