import { graphql, Link, navigate, Script, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react'
import InputError from '../../../components/input-error';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { LineItemType } from '../../../components/templates/product/types';
import { customEvent, initiateCheckout, purchase } from '../../../helpers/analytics/facebook-pixel';
import { AdvancedMatchingOptionsType } from '../../../helpers/analytics/facebook-pixel/types';
import { extractStoreKeyFromShippingUrl } from '../../../helpers/data/tools';
import { objectifyFormData } from '../../../helpers/form';
import httpRequest from '../../../helpers/http';
import CartStorage from '../../../helpers/storage';
import { validateBillingDetails } from '../../../helpers/validator/checkout';
import { useCart } from '../../../hooks/useCart';
import useFreshShipping from '../../../hooks/useShipping';
import { NodeEdges, ProductType, ShippingLineType, ShippingZoneType } from '../../../types/global';
import CheckoutPageStaticQueryType from '../../../types/pages/checkout';
import { scrollIntoView } from '../../../helpers/ui';

export default function Checkout({ location }: any) {
  const fbEventId = useRef(`event.id.ic-${Math.random() * 1000000}-${Date.now()}`).current;
  const [state, dispatch] = useCart();
  const [shippingMode, setShippingMode] = useState<"door" | "stop-desk">("door");
  const alertType = !!state.message ? "success" : "danger";
  const {
    websiteDetails,
    storeShipping,
    site: {
      buildTime,
      siteMetadata: {
        checkout_url
      }
    },
  } = useStaticQuery<CheckoutPageStaticQueryType>(graphql`
    query CheckoutPage {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          checkout_url
        }
      }
      websiteDetails: zzStoreWebsiteDetails {
				store_checkout_url
        store_shipping
        product_stock_url
        store_primary_settings {
          store_analytics {
            facebook_pixel
          }
        }
      }
      storeShipping: allZzStoreShipping(sort: {fields: [zzenz_id], order: ASC}) {
        edges {
          node {
            zzenz_id
            name
            cost
            location {
              code
              type
              name
            }
          }
        }
      }
    }
  `);
  const [shippingState] = useFreshShipping(websiteDetails.store_shipping, storeShipping.edges);
  const buildDates = {
    first_publication_date: buildTime,
    last_publication_date: buildTime,
  };
  const facebookPixelId = websiteDetails.store_primary_settings.store_analytics.facebook_pixel;
  let productContent: Record<string, string>[] = [];
  let numItems = 0;
  const productIds = state.data?.lineItems.reduce((acc: [], item: Record<string, string>) => {
    numItems += Number(item?.quantity);
    productContent.push({
      id: item?.product_id,
      quantity: item?.quantity
    });
    return [...acc, item?.product_id]
  }, []);
  const shippingList = shippingState.data.filter(({ node: item }: NodeEdges<ShippingZoneType>) => {
    if (shippingMode === "door") return !!item["cost"];
    return !!item["stop-desk-cost"];
  });
  /**
    * Display or not the shipping mode depend on the selected state
    * 
    * @returns shipping config mode
    */
  const getShippingConfig = () => {
    const selectedStateCode = getShippingLocation();

    if (!selectedStateCode) {
      return {
        withStopDesk: true,
        withHomeDelivery: true,
      }
    }

    const selectShippingDetails = shippingList.find(({ node: { location } }) => {
      return location.code === selectedStateCode;
    });

    return {
      withStopDesk: !!selectShippingDetails?.node?.["stop-desk-cost"],
      withHomeDelivery: !!selectShippingDetails?.node?.["cost"],
    }
  }
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch({
      type: "LOADING",
      payload: true
    });
    !!facebookPixelId && customEvent("StartPurchase", {
      timestamp: Date.now()
    });

    const { currentTarget: form } = event;
    const { action, method } = form;
    const formData = new FormData(form);
    const { shipping, billing } = objectifyFormData(formData);
    const errors = validateBillingDetails(billing as Record<string, string>);

    if (errors !== false) {
      dispatch({
        type: "ERROR",
        payload: errors
      });
      !!facebookPixelId && customEvent("ValidationErrorInPurchase", {
        contents: errors,
        shipping,
        billing,
        timestamp: `${Date.now()}`
      });
    } else {
      const stateLocation = storeShipping.edges.find(({ node }) => {
        return node.location.code === (billing as any)?.state;
      });

      if ((billing as any)?.state) {
        (billing as any).state = stateLocation?.node.location.name;
      }

      if (!!state.data.shippingLines?.[0]) {
        delete state.data.shippingLines?.[0].id;
      }

      const order = {
        line_items: state.data.lineItems,
        billing,
        shipping,
        shipping_lines: state.data.shippingLines,
      };

      try {
        /**
         * Verify if the given product is already in the cart
         * then add that quantity to the new one and check its availability
         */
        for (let index = 0; index < order.line_items.length; index++) {
          const product = order.line_items[index];
          const response = await httpRequest({
            url: `${websiteDetails.product_stock_url}/${product.product_id}/availability?quantity=${product.quantity}`,
            requestConfig: {
              method: "GET",
            }
          });

          if (response.code !== "success") {
            dispatch({
              type: "ERROR",
              payload: {
                global: "نفد هذا المنتج من المخزن"
              } || response.errors
            });
            const elementClassName = response?.errors?.global
              ? ".alert"
              : ".invalid-feedback";
            scrollIntoView(document.querySelector(elementClassName));
            return;
          }
        }

        const response = await httpRequest({
          url: action,
          requestConfig: {
            method,
            body: JSON.stringify(order),
            headers: {
              "Content-Type": "application/json"
            }
          }
        });

        if (response?.code === "created") {
          dispatch({
            type: "SUCCESS",
            payload: "تم إنشاء طلبك بنجاح" || response.message
          });
          const cart = new CartStorage();
          cart.clearshoppingCart();
          if (typeof window !== "undefined") {
            window.scrollTo({
              behavior: "smooth",
              left: 0,
              top: 150
            });
          }
          // send standard events 2 meta pixel
          if (!!facebookPixelId) {
            let { first_name: fn, last_name: ln, phone: ph, email: em, state: st } = billing as Record<string, string>;
            purchase(
              getLineItemsTotal(),
              productIds,
              productContent,
              "دج",
              "product",
              {
                pixelId: facebookPixelId,
                sourceUrl: window.location.href,
                storeKey: extractStoreKeyFromShippingUrl(websiteDetails.store_shipping),
                eventID: `event.id.p-${response.data.id}`
              },
              {
                fn,
                ln,
                em,
                ph,
                st,
              }
            );
            !!facebookPixelId && customEvent("EndOfPurchase", {
              timestamp: `${Date.now()}`
            });
            let fbpOptions: AdvancedMatchingOptionsType = {
              fn,
              ln,
              ph,
              st,
              country: "dz"
            };
            fbpOptions = !!em ? { ...fbpOptions, em } : fbpOptions;

            await navigate(`/cart/checkout/`, {
              state: {
                fbp: fbpOptions
              },
            });
            setTimeout(() => {
              window?.location?.reload();
            }, 3_500);
          }
        } else {
          dispatch({
            type: "ERROR",
            payload: response.errors
          });
          if (typeof window !== "undefined") {
            window.scrollTo({
              behavior: "smooth",
              left: 0,
              top: 150
            });
          }
          !!facebookPixelId && customEvent("ServerErrorInPurchase", {
            contents: response.errors
          });
        }
      } catch (error) {
        dispatch({
          type: "ERROR",
          payload: {
            global: "Une erreur s'est produite. Veuillez réessayer!"
          }
        });
        if (typeof window !== "undefined") {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 150
          });
        }
        !!facebookPixelId && customEvent("BrowserErrorInPurchase", {
          contents: error
        });
      }
    }
  }
  const updateSelectedShippingByMode = async (mode: "stop-desk" | "door", selectedShipping: NodeEdges<ShippingZoneType>) => {
    const cart = new CartStorage();
    const total = mode === "stop-desk"
      ? (selectedShipping?.node["stop-desk-cost"] || selectedShipping?.node.cost)
      : selectedShipping?.node.cost;

    const shippingLine: ShippingLineType = {
      method_id: "flat_rate",
      id: selectedShipping?.node?.zzenz_id,
      method_title: selectedShipping?.node.name,
      total: String(total)
    };
    dispatch({
      type: "SET_FIELD",
      payload: {
        key: "shippingLines",
        value: [shippingLine]
      }
    });
    await cart.updateShippingLine(shippingLine);
  }
  const onEditShipping = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    const selectedShippind = shippingState.data.find(({ node: { location } }: NodeEdges<ShippingZoneType>) => location.code === value);

    if (selectedShippind !== undefined) {
      await updateSelectedShippingByMode(shippingMode, selectedShippind);
    }
  }
  const getShippingLocation = () => {
    let selectedShippind = storeShipping.edges.find(({ node: { zzenz_id } }) => {
      return zzenz_id === state.data.shippingLines?.[0]?.id;
    });

    return selectedShippind?.node?.location?.code || "";
  }
  const getLineItemsTotal = () => {
    const totalPrice = state.data?.lineItems?.reduce((acc: number, { product_id, quantity }: LineItemType) => {
      const { regular_price } = state.data.products[product_id];
      return acc + (regular_price * quantity);
    }, 0);

    return totalPrice;
  }
  const getShippingTotal = () => {
    if (state.data.shippingLines.length > 0) {
      return parseFloat(state.data.shippingLines?.[0]?.total) || 0;
    } else {
      return 0;
    }
  }
  const onChangeShippingMode = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const mode = value as "door" | "stop-desk";
    setShippingMode(mode);

    let selectedShipping = shippingState.data.find(({ node: { zzenz_id } }: NodeEdges<ShippingZoneType>) => {
      return zzenz_id === state.data?.shippingLines[0]?.id
    });
    selectedShipping = mode === "stop-desk" && !selectedShipping?.node?.["stop-desk-cost"]
      ? undefined
      : selectedShipping;

    if (selectedShipping !== undefined) {
      await updateSelectedShippingByMode(mode, selectedShipping);
    } else {
      dispatch({
        type: "SET_FIELD",
        payload: {
          key: "shippingLines",
          value: []
        }
      });
    }
  }
  const total = getLineItemsTotal();
  const shippingConfig = getShippingConfig();

  useEffect(() => {
    (async () => {
      if (!!facebookPixelId && total > 0) {
        await initiateCheckout(
          {
            "num_items": numItems || 1,
            "content_ids": productIds,
            "value": getLineItemsTotal(),
            "currency": "DZD",
            "contents": productContent as any,
          },
          {
            pixelId: facebookPixelId,
            sourceUrl: location.href,
            storeKey: extractStoreKeyFromShippingUrl(websiteDetails.store_shipping),
            eventID: fbEventId
          }
        );
      }

      const cart = new CartStorage();
      const oldShipping = await cart.getShippingLines() as [];

      if (state.data.shippingLines?.length === 0 && oldShipping.length === 0) {
        const zone = storeShipping.edges?.[0]?.node;
        const shippingLines: ShippingLineType = {
          id: zone?.zzenz_id,
          method_id: "flat_rate",
          method_title: zone?.name,
          total: zone?.cost
        };
        dispatch({
          type: "SET_FIELD",
          payload: {
            key: "shippingLines",
            value: shippingLines
          }
        });
        await cart.updateShippingLine(shippingLines);
      }
    })();
  }, [total]);

  return (
    <Layout>
      <SEO
        title={"الدفع"}
        pathname={"/cart/checkout/"}
        description={""}
        banner={""}
        node={buildDates}
        facebbokPixelAdvancedMatching={location.state?.fbp}
      />

      <div className={"container"}>
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row px-4 px-lg-5 py-lg-4 align-items-center">
              <div className="col-lg-6" dir="rtl">
                <h1 className="h2 text-uppercase mb-0">الدفع</h1>
              </div>
              <div className="col-lg-6 text-lg-end">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-lg-end mb-0 px-0 bg-light">
                    <li className="breadcrumb-item">
                      <Link className="text-dark" to={"/"}>الرئسية</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link className="text-dark" to="/cart/">السلة</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      الدفع
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <h2 className="h5 text-uppercase mb-4" dir={"rtl"}>تفاصيل الفاتورة</h2>
          {
            (state?.message || state?.errors?.global || state?.errors?.line_items) && (
              <div className={`alert alert-${alertType}`} dir="rtl">
                {state?.message || state?.errors?.global || state?.errors?.line_items}
              </div>
            )
          }
          <form
            action={`${`${websiteDetails.store_checkout_url}` || checkout_url}`}
            method='POST'
            onSubmit={onSubmit}
            className="row"
          >
            <div className="col-lg-8 mb-4 mb-lg-0">
              {/* BILLING ADDRESS */}
              <div className="row gy-3">
                <div className="col-lg-6" dir="rtl">
                  <label className="form-label text-sm text-uppercase" htmlFor="firstName">الاسم الأول</label>
                  <input className="form-control form-control-lg" type="text" name="billing[first_name]" id="firstName" placeholder="اسمك" />
                  <InputError message={state.errors?.billing?.first_name} />
                </div>
                <div className="col-lg-6" dir="rtl">
                  <label className="form-label text-sm text-uppercase" htmlFor="lastName">اسم العائلة</label>
                  <input className="form-control form-control-lg" type="text" name="billing[last_name]" id="lastName" placeholder="اسم العائلة" />
                  <InputError message={state.errors?.billing?.last_name} />
                </div>
                <div className="col-lg-6" dir="rtl">
                  <label className="form-label text-sm text-uppercase" htmlFor="email">بريد إلكتروني</label>
                  <input className="form-control form-control-lg" type="email" name="billing[email]" id="email" placeholder="e.g. mohammed@example.com" />
                  <InputError message={state.errors?.billing?.email} />
                </div>
                <div className="col-lg-6" dir="rtl">
                  <label className="form-label text-sm text-uppercase" htmlFor="phone">رقم الهاتف</label>
                  <input className="form-control form-control-lg" type="tel" name="billing[phone]" id="phone" placeholder="775354745" dir="rtl" />
                  <InputError message={state.errors?.billing?.phone} />
                </div>
                <div className="col-lg-6" dir="rtl">
                  <label className="form-label text-sm text-uppercase" htmlFor="address_1">عنوان</label>
                  <input className="form-control form-control-lg" type="text" name="billing[address_1]" id="address_1" placeholder="عنوانك" />
                  <InputError message={state.errors?.billing?.address_1} />
                </div>
                <div className="col-lg-6" dir="rtl">
                  <label className="form-label text-sm text-uppercase" htmlFor="state">ولاية</label>
                  <select
                    name="billing[state]"
                    id="state"
                    className={"form-control form-control-lg rounded-0"}
                    onChange={onEditShipping}
                    value={getShippingLocation()}
                    disabled={state.data?.lineItems.length === 0}
                  >
                    <option value={""} disabled>
                      ولاية
                    </option>
                    {
                      shippingList.map(({ node: { location, } }: NodeEdges<ShippingZoneType>) => {
                        const label = shippingMode === "stop-desk"
                          ? `${location.name}`
                          : location.name;

                        return (
                          <option
                            value={location.code}
                            key={location.code}>
                            {label}
                          </option>
                        );
                      })
                    }
                  </select>
                  <InputError message={state.errors?.billing?.state} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card border-0 rounded-0 p-lg-4 bg-light">
                <div className="card-body">
                  <h5 className="text-uppercase mb-4" dir='rtl'>طلبيتك</h5>
                  <ul className="list-unstyled mb-0">
                    {
                      state.data?.lineItems?.map(({ product_id, quantity }: LineItemType, index: number) => {
                        const product: Required<ProductType> = state.data.products[product_id];

                        return (
                          <React.Fragment key={`checkout-item-${product.slug}-${product_id}-${index}`}>
                            <li className="d-flex align-items-center justify-content-between" dir='rtl'>
                              <strong className="small fw-bold">{product.name}</strong>
                              <span className="text-muted small">{product.regular_price}دج &times; {quantity}</span>
                            </li>
                            <li className="border-bottom my-2"></li>
                          </React.Fragment>
                        )
                      })
                    }
                    <li dir='rtl'>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <strong className="small fw-bold">التوصيل</strong>
                        <span className="text-muted small">{getShippingTotal()}دج</span>
                      </div>
                      <div className="d-flex flex-column">
                        <label htmlFor="door" className="form-label text-sm mb-1">
                          <input
                            onChange={onChangeShippingMode}
                            type="radio"
                            name="shipping-mode"
                            value="door"
                            id="door"
                            className="form-check-input mx-2"
                            defaultChecked={shippingMode === "door"}
                            disabled={shippingConfig.withHomeDelivery === false}
                          />
                          <span>في البيت</span>
                        </label>
                        <label htmlFor="stop-desk" className="form-label text-sm mb-0">
                          <input
                            onChange={onChangeShippingMode}
                            type="radio"
                            name="shipping-mode"
                            value="stop-desk"
                            id="stop-desk"
                            className="form-check-input mx-2"
                            defaultChecked={shippingMode === "stop-desk"}
                            disabled={shippingConfig.withStopDesk === false}
                          />
                          <span>مكتب التوقف</span>
                        </label>
                      </div>
                    </li>
                    <li className="border-bottom my-2"></li>
                    <li className="d-flex align-items-center justify-content-between" dir='rtl'>
                      <strong className="text-uppercase small fw-bold">المجموع</strong>
                      <span>{getLineItemsTotal() + getShippingTotal()}دج</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 form-group mt-4">
              <button
                className="btn btn-dark"
                type="submit"
              // disabled={state.loading || shippingState.loading}
              >
                أكد الطلب
              </button>
            </div>
          </form>
        </section>

      </div>

      {
        !!facebookPixelId && !state.loading && !state.message && !state.errors && (
          <Script strategy="idle">{`
            fbq('track', 'InitiateCheckout', {
              "contents": ${JSON.stringify(productContent)},
              "num_items": ${numItems || 1},
              "content_ids": ${JSON.stringify(productIds)},
              "value": ${getLineItemsTotal()},
              "currency": "دج",
              "timestamp": "${Date.now()}"
            }, { "eventID": "${fbEventId}" });
          `}</Script>
        )
      }

    </Layout>
  );
}
