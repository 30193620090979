import React from "react";
import InputErrorPropsTypes from "./types";

export default function InputError({ message }: InputErrorPropsTypes) {
  if (!message) return null;

  return (
    <div className="invalid-feedback d-block">{message}</div>
  );
}
